import React from 'react'
import styled from "styled-components";

const Img = styled.img`
width: 700px;
height: 500px;
object-fit: contain;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
margin: auto;


@keyframes animate {
 to{
  transform: translateY(20px);
 }
}
`;

const Music = () => {
  return (
    <Img src="./img/moon.png"/>
  )
}

export default Music