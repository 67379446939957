import {React, Suspense} from 'react'
import styled from 'styled-components'

import { Canvas } from "@react-three/fiber";

import { Experience } from './Experience';

const Section = styled.div`
height: 100vh;
scroll-snap-align: center;
display: flex;
justify-content: space-between;
justify-content: center;
`;

const Container = styled.div`
height: 100vh;
scroll-snap-align: center;
width: 1400px;
display: flex;

`;

const Left = styled.div`
flex: 1;
@media only screen and (max-width: 768px) {
    display: none;
  }
`;


const Right = styled.div`
flex: 1;
display: flex;
flex-direction: column;
justify-content: center;

@media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
font-size: 74px;
@media only screen and (max-width: 768px) {
    font-size: 60px;
  }
`;

const Desc = styled.p`
font-size: 24px;
color: lightgray;
`;
const Button = styled.button`
background-color: #b37ce0;
color: white;
font-weight: 500;
width: 100px;
padding: 10px;
border: none;
border-radius: 5px;
cursor: pointer;
`;

export const Who = () => {
  return (
    <Section>
      
      <Container>
        <Left>

        <Canvas shadows camera={{ position: [0, 2, 5], fov: 30 }}>
     
      <Experience/>
    </Canvas>
        </Left>
        <Right>
        <Title>Learn more about me</Title>
          <Desc>I specialize in React development for front-end applications. 
            <br/>My expertise lies in translating design concepts into functional and responsive interfaces. 
            </Desc>
            <a href="https://www.linkedin.com/in/pongó-lajos-280838186/" target="blank" class="button"> <Button>Go to LinkedIn</Button></a>
         
        </Right>
      </Container>
      
      </Section>
  )
}
export default Who;


