import {
    ContactShadows,
    Environment,
    OrbitControls,
    Sky,
  } from "@react-three/drei";
  import { useControls } from "leva";
import { Myself } from "./Myself";

 
export const Experience = () => {
   
    return (
      <>
        <OrbitControls enableZoom={false}/>
     
        <Environment preset="sunset" />
        <group position-y={-1}>
          <ContactShadows
            opacity={0.42}
            scale={10}
            blur={1}
            far={10}
            resolution={256}
            color="#000000"
          />
          <Myself/>
        
        
        </group>
      </>
    );
  };