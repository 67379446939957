import { Canvas } from "@react-three/fiber";
import Cube from "./Cube";
import React from 'react';
import styled from 'styled-components';
import { OrbitControls, Stage,PerspectiveCamera, RenderTexture, Text } from "@react-three/drei";
import { Link } from "react-router-dom";
import { AmbientLight } from "three";


const Container = styled.div`
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
`;

const Test = () => {
  return (
   <Link to ='/test' className="test">
    <Container>
        
        <Canvas>
            <OrbitControls enableZoom={false}/>
            <ambientLight intensity ={1}/>
            <directionalLight position={[3,2,1]}/>
            <Cube/>
        </Canvas>
      
    </Container>
    </Link>
  )
}

export default Test
