import React from 'react';
import './App.css';
import ComingSoon from './comingSoon';
import Hero from './components/Hero';
import Who from './components/Who';
import Contact from './components/Contact';
import Works from './components/Works';
import styled from "styled-components";
import Test from './components/Test';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Experience } from './components/Experience';
import { Canvas } from "@react-three/fiber";


const Container = styled.div`
height: 100vh;
scroll-snap-type: y mandatory;
scroll-behavior: smooth;
overflow-y: auto;
scrollbar-width: none;
color: white;
background: url("./img/bg3.png");
&::-webkit-scrollbar{
  display: none;
}
`

function App() {
  return (

    <Container>
      <BrowserRouter>
      <Routes>
        <Route path="/test" element={<Test/>}/>
      </Routes>
      </BrowserRouter>

    <Hero/>
    <Who/>
    
    <Contact/>
  

  </Container>
  );
}

export default App;
