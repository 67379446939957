import React from 'react';
import './App.css';
import styled from 'styled-components';

const Img = styled.img`
width: 150px;
`;

function ComingSoon() {
  return (
    <div className="App">
      <header className="App-header">
      <Img src="./img/moon.png"/>
        <h1>Coming Soon</h1>
        <p>I'm working hard to bring you an amazing website.</p>
        <p>Lajos Pongo!</p>
      </header>
    </div>
  );
}

export default ComingSoon;
