
import {React, useState} from 'react'
import { Link } from 'react-router-dom';

import styled from 'styled-components'





const Section = styled.div`
display: flex;
justify-content: center;

@media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
width: 1400px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 0px;

@media only screen and (max-width: 768px) {
    width: 100%;
    pad: 10px;
  }
`;

const Links = styled.div`
display: flex;
align-items: center;
gap: 50px;
`;

const Logo = styled.img`
height: 50px;
`;
const List = styled.ul`
display: flex;
gap: 20px;
list-style: none;
@media only screen and (max-width: 768px) {
    display: none;
  }
`;
const ListItem = styled.li`
cursor: pointer;
`;
const Icon = styled.img`
width: 20px;
cursor: pointer;
`;

const Icons = styled.div`
display: flex;
align-items: center;
gap: 50px;
`;
const Button = styled.div`
width: 100px;
padding: 10px;
background-color: #b37ce0;
color: white;
text-align: center;
cursor: pointer;
border: none;
border-radius: 5px;
`;


const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false)

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  return (
   
    <Section>
      <Container>
        <Links>
        <Logo src="./img/logo2.png"/>
        <List>
       
         
   
        </List>
        </Links>
        <Icons>
    
       <Button>Contact me</Button>
      
        </Icons>
      </Container>
    </Section>
  )
}

export default Navbar